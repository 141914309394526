<template>
  <!-- eslint-disable max-len -->
  <svg
    viewBox="0 0 150 56">
    <path
      :fill="color || '#99CCEE'"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 20.504H4.7372C5.75971 20.504 6.72503 20.7021 7.63201 21.0978C8.53949 21.4941 9.33497
      22.0343 10.0192 22.7184C10.7033 23.4026 11.2429 24.1982 11.6397 25.105C11.8822 25.6613
      12.0498 26.239 12.1438 26.8385C12.3728 25.2696 13.0791 23.8921 14.2641 22.7075C15.7041
      21.2381 17.468 20.504 19.5564 20.504C21.6305 20.504 23.3944 21.2381 24.8494 22.7075C25.9218
      23.7698 26.6045 24.9869 26.9015 26.358V20.504L34.3973 27.9998L41.9153 20.504V26.3499C42.2105
      24.9822 42.8863 23.7672 43.9461 22.7075C45.3864 21.2381 47.1509 20.504 49.2393 20.504C51.3133
      20.504 53.0766 21.2381 54.5316 22.7074C55.1508 23.3203 55.6388 23.9864 56
      24.7025V0H0V20.504ZM49.2394 35.4962C47.1653 35.4962 45.4009 34.762 43.946 33.2926C42.8863
      32.2433 42.2104 31.0313 41.9153 29.6588V35.496H39.3872V26.531L34.3973 31.5426L29.3862
      26.531V35.4962H26.9015V29.6595C26.6044 31.0396 25.9218 32.2514 24.8494 33.2926C23.3944 34.762
      21.6305 35.4962 19.5565 35.4962C17.4831 35.4962 15.7186 34.762 14.2641 33.2926C13.0781
      32.1189 12.3718 30.7424 12.1431 29.1652C12.0488 29.7633 11.8816 30.34 11.6397 30.8948C11.2429
      31.8022 10.7069 32.5983 10.03 33.2819C9.35317 33.966 8.55758 34.506 7.64287 34.9018C6.72823
      35.2987 5.75987 35.4962 4.73726 35.4962H0V56.0001H56V31.3182C55.6388 32.0327 55.1508 32.6916
      54.5316 33.2926C53.0767 34.762 51.3133 35.4962 49.2394 35.4962ZM9.33869 29.9225C9.07974
      30.5277 8.72325 31.0601 8.2692 31.5213C7.81566 31.9821 7.28634 32.3421 6.68166
      32.6011C6.07702 32.8606 5.42849 32.9901 4.73717 32.9901H2.2314V23.0096H4.73717C5.41404
      23.0096 6.05535 23.1391 6.65998 23.3987C7.26473 23.6577 7.79757 24.0177 8.25889
      24.4785C8.71961 24.9397 9.07969 25.4722 9.33869 26.0774C9.59825 26.682 9.72772 27.3232
      9.72772 27.9997C9.72772 28.677 9.59825 29.3173 9.33869 29.9225ZM52.7599 31.5425C53.7391
      30.5489 54.2292 29.3685 54.2292 27.9998C54.2292 26.632 53.7391 25.4584 52.7599
      24.4786C51.7808 23.4994 50.6075 23.0098 49.2394 23.0098C47.8712 23.0098 46.6897 23.4994
      45.6961 24.4786C44.7312 25.4583 44.2494 26.632 44.2494 27.9998C44.2494 29.3685 44.7312
      30.5489 45.6961 31.5425C46.6897 32.5079 47.8711 32.9904 49.2394 32.9904C50.6075 32.9903
      51.7807 32.5079 52.7599 31.5425ZM24.547 27.9998C24.547 29.3685 24.0574 30.5489 23.0783
      31.5425C22.0985 32.5079 20.9248 32.9903 19.5565 32.9904C18.1883 32.9904 17.0074 32.5079
      16.0137 31.5425C15.0489 30.5489 14.5666 29.3685 14.5666 27.9998C14.5666 26.632 15.0489
      25.4583 16.0137 24.4786C17.0074 23.4994 18.1883 23.0098 19.5565 23.0098C20.9248 23.0098
      22.0985 23.4994 23.0783 24.4786C24.0574 25.4584 24.547 26.632 24.547 27.9998Z"
    />

    <path
      :fill="color || '#333333'"
      d="M86.9545 33.8068V22.5568H88.8636V33.8068H86.9545ZM82.2841
      29.1363V27.2272H93.5341V29.1363H82.2841Z"
    />

    <g xmlns="http://www.w3.org/2000/svg" clip-path="url(#clip1_73_63)" transform="translate(115, 0)">
      <g transform="scale(0.40)">
        <path fill="#95BF47" d="M107.4,27.1c-0.1-0.7-0.7-1.1-1.2-1.1c-0.5,0-10.4-0.2-10.4-0.2s-8.3-8-9.1-8.9c-0.8-0.8-2.4-0.6-3-0.4    c0,0-1.6,0.5-4.2,1.3c-0.4-1.4-1.1-3.1-2-4.9c-2.9-5.6-7.3-8.6-12.5-8.6c0,0,0,0,0,0c-0.4,0-0.7,0-1.1,0.1    c-0.2-0.2-0.3-0.4-0.5-0.5c-2.3-2.4-5.2-3.6-8.7-3.5c-6.7,0.2-13.4,5.1-18.9,13.7c-3.8,6.1-6.7,13.7-7.6,19.6    c-7.7,2.4-13.1,4.1-13.3,4.1c-3.9,1.2-4,1.3-4.5,5C10.2,45.6,0,124.5,0,124.5l85.6,14.8l37.1-9.2    C122.6,130.1,107.5,27.8,107.4,27.1z M75.2,19.2c-2,0.6-4.2,1.3-6.6,2.1c0-3.4-0.5-8.2-2-12.2C71.7,9.9,74.2,15.7,75.2,19.2z     M64.1,22.6c-4.5,1.4-9.4,2.9-14.3,4.4c1.4-5.3,4-10.5,7.2-14c1.2-1.3,2.9-2.7,4.8-3.5C63.8,13.4,64.2,18.9,64.1,22.6z M54.9,4.9    c1.6,0,2.9,0.3,4,1.1c-1.8,0.9-3.6,2.3-5.2,4.1c-4.3,4.6-7.6,11.7-8.9,18.6c-4.1,1.3-8.1,2.5-11.7,3.6    C35.5,21.4,44.6,5.2,54.9,4.9z"/>
        <g>
          <path fill="#5E8E3E" d="M106.2,26c-0.5,0-10.4-0.2-10.4-0.2s-8.3-8-9.1-8.9c-0.3-0.3-0.7-0.5-1.1-0.5l0,122.9l37.1-9.2     c0,0-15.1-102.3-15.2-103C107.3,26.4,106.7,26,106.2,26z"/>
          <path fill="#FFFFFF" d="M65,45.1l-4.3,16.1c0,0-4.8-2.2-10.5-1.8c-8.4,0.5-8.4,5.8-8.4,7.1c0.5,7.2,19.4,8.8,20.5,25.7     c0.8,13.3-7,22.4-18.4,23.1C30.3,116,22.8,108,22.8,108l2.9-12.3c0,0,7.6,5.7,13.6,5.3c3.9-0.2,5.4-3.5,5.2-5.7     c-0.6-9.4-16-8.8-17-24.3C26.7,58,35.2,44.9,54,43.7C61.3,43.2,65,45.1,65,45.1z"/>
        </g>
      </g>
</g>

  </svg>
</template>

<script>
export default {
  name: 'DomoPlusShopifyLogo',

  props: {
    color: {
      type: String,
      default: null,
    },
  },
};
</script>
